









































import IntegrityTable, { FillFunctionContainer } from '@/components/IntegrityTable/IntegrityTable.vue';
import { Component, Prop } from 'vue-property-decorator';
import { CustomerData } from '@/store/customers/types';
import phoneNumberToPhoneInfo from '@/components/IntegrityPhoneNumberInput/types';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import { uuid } from 'vue-uuid';
import { DetailedUserData } from '@/store/users/types';
import { OrganizationData } from '@/store/organizations/types';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import CustomerEditDialog from './CustomerEditDialog.vue';

@Component({
  components: {
    IntegrityTable,
    CustomerEditDialog,
    IntegrityDelete,
  },
})
export default class CustomersTable extends AdminPanelMixin {
  @Prop() readonly search: string;

  @Prop() readonly canEdit: boolean;

  @Prop() readonly canDelete: boolean;

  showEditDialog = false;

  currentEditItem = {};

  headers = [
    {
      text: 'Customer',
      value: 'name',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Organization',
      value: 'organizationName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Address',
      value: 'address',
      sortable: true,
      filterable: true,
    },
    {
      text: 'System Manager',
      value: 'systemManager',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Phone',
      value: 'phoneNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'left',
      sortable: false,
    },
  ];

  matchFilters = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'organizationName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'address',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'systemManager',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'email',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'phoneNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'systemOwner',
          functionVariables: [this.allUsers],
          fillFunction:
            /**
            * @description Find the user in the all users object based on their guid.
            * @param {string} userGuid User guid to search
            * @returns {string} first and last name of user
            */
            function findUserFromUserGuid(
              item: CustomerData,
              allUsers: DetailedUserData[],
            ): string {
              if (allUsers == null) {
                return '';
              }
              const foundUser = allUsers.find((u) => u.guid === item.systemOwnerGuid);
              if (foundUser) {
                return `${foundUser.firstname} ${foundUser.lastname}`;
              }
              return '';
            },
        },
        {
          headerValue: 'organizationName',
          functionVariables: [this.organizationDataList],
          fillFunction:
            /**
            * @description Find the user in the all users object based on their guid.
            * @param {string} userGuid User guid to search
            * @returns {string} first and last name of user
            */
            function findOrganizationFromOrganizationGuid(
              item: CustomerData,
              organizationDataList: OrganizationData[],
            ): string {
              if (organizationDataList == null) {
                return '';
              }
              const foundOrganization = organizationDataList.find(
                (org) => org.guid === item.organizationGuid,
              );
              return foundOrganization?.name ?? '';
            },
        },
      ],
    };
    return returnValue;
  }

  async mounted(): Promise<void> {
    if (!this.customerDataList) await this.fetchCustomerData();
    this.$root.$on('clearUserTableFilters', () => {
      (this.$refs.customerTable as IntegrityTable).clearAllMatchFilters();
    });
  }

  /**
   * @description Get the data for the table that includes the system manager's name.
   * @returns {CustomerData[]} List of customers
   */
  get customerTableData(): CustomerData[] {
    if (!this.customerDataList) {
      return undefined;
    }
    return this.customerDataList;
  }

  handleEdit(itemGuid: string): void {
    const customerData = this.customerTableData.find((i) => i.guid === itemGuid);
    let organizationEditData = null;
    if (customerData.organizationGuid) {
      const organizationData = this.organizationDataList
        .find((o) => o.guid === customerData.organizationGuid);
      if (organizationData) {
        organizationEditData = {
          guid: organizationData.guid,
          name: organizationData.name,
        };
      }
    }
    this.currentEditItem = {
      guid: customerData.guid,
      customerName: customerData.name,
      address: customerData.address,
      systemManager: customerData.systemOwnerGuid ? {
        guid: customerData.systemOwnerGuid,
        firstname: customerData.systemManager && customerData.systemManager !== '' ? customerData.systemManager.split(' ')[0] : '',
        lastname: customerData.systemManager && customerData.systemManager !== '' ? customerData.systemManager.split(' ')[1] : '',
      } : null,
      email: customerData.email,
      phoneNumber: phoneNumberToPhoneInfo(customerData.phoneNumber),
      organization: organizationEditData,
      sameAsOrganization: organizationEditData && (organizationEditData.name === customerData.name),
    };
    this.showEditDialog = true;
  }

  /**
   * @description Calls endpoint to delete customer and removes it from customerTableData.
   * @param {CustomerData} item CustomerData object to remove.
   * @returns {Promise<boolean>} Returns true if customer was successfully deleted.
   */
  async handleDelete(item: CustomerData): Promise<boolean> {
    try {
      const deleteIndex = this.customerTableData.findIndex(
        (value) => value.guid === item.guid,
      );

      if (deleteIndex === -1) {
        // If we can not find the item in the customerTableData
        return false;
      }

      await this.deleteCustomerData([item.guid]);
      this.customerDataList.splice(deleteIndex, 1);
    } catch (e) {
      return false;
    }
    return true;
  }
}
