var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-users"},[_c('IntegrityTable',{ref:"usersTable",attrs:{"tableID":'ManageUsersTable',"data":_vm.userDataFiltered,"headers":_vm.headers,"expanded":_vm.expanded,"showExpand":true,"showSelect":true,"selectedItems":_vm.synchedSelectedItems,"height":'100%',"search":_vm.search,"matchFilters":_vm.matchFilters,"dataFillFunctions":_vm.fillTableDataFunctions,"loading":_vm.usersLoading || _vm.inTimeout},on:{"update:data":function($event){_vm.userDataFiltered=$event},"update:headers":function($event){_vm.headers=$event},"update:expanded":function($event){_vm.expanded=$event},"update:selectedItems":function($event){_vm.synchedSelectedItems=$event},"update:selected-items":function($event){_vm.synchedSelectedItems=$event},"edit":_vm.editPage,"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event}},scopedSlots:_vm._u([{key:"delete",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermissionAdminDeleteUser)?_c('IntegrityDelete',{attrs:{"deleteFunction":_vm.deleteUser.bind(this, item),"loading":_vm.adminLoading},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('v-btn',{staticClass:"delete-btn",attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]},proxy:true}],null,true)}):_vm._e()]}},{key:"reset",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermissionAdminResetUserPassword)?_c('div',[_c('div',{staticClass:"button-wrapper reset-pass-btn",on:{"click":function($event){return _vm.resetPasswordDialog(item)}}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-cached")])],1)],1),_c('v-dialog',{attrs:{"width":"40%","retain-focus":false},model:{value:(item.resetDialog),callback:function ($$v) {_vm.$set(item, "resetDialog", $$v)},expression:"item.resetDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Password Reset")]),_c('v-card-text',{staticClass:"dialogText"},[_vm._v("Are you sure you want to reset password?")]),_c('v-card-actions',[_c('v-btn',{staticClass:"cancel-btn",attrs:{"color":"#0c6599","outlined":""},on:{"click":function($event){return _vm.resetPasswordDialog(item)}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"integrity-blue-button reset-btn",on:{"click":function($event){_vm.resetPassword(item.guid); _vm.resetPasswordDialog(item)}}},[_vm._v("Reset")])],1)],1)],1)],1):_vm._e()]}},{key:"expanded-content",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"id":"expanded-content"}},[_c('ProjectList',{staticClass:"expanded-project-list",attrs:{"user":item,"projects":_vm.allProjects,"search":_vm.search,"hideDefaultFooter":"true"},on:{"deleteUser":_vm.deleteUserInterface}})],1)]}},{key:"userStatus",fn:function(ref){
var item = ref.item;
return [(item.detailedUser != null)?_c('v-chip',{attrs:{"small":"","color":_vm.getStatusColor(item.detailedUser),"text-color":_vm.getStatusTextColor(item.detailedUser)}},[_vm._v(" "+_vm._s(item.userStatus)+" ")]):_c('v-chip',{attrs:{"x-small":"","color":_vm.getStatusColor(item.detailedUser),"text-color":_vm.getStatusTextColor(item.detailedUser)}},[_vm._v(" No Data ")])]}}])}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"plain":"","small":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v("Dismiss")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }